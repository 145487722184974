import * as React from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { Button, DatePicker, Form, FormListFieldData, Input, InputNumber, Modal, Row, Select } from 'antd';
import { useStore } from '../../common/stores';
import { Task } from 'src/modules/tasks/types';
import { DashboardWidgetMeta, ExtraDateFilter, FilterOperator, WidgetFilter } from '../types/DashboardWidgetMeta';
import FormItem from 'antd/lib/form/FormItem';
import { useForm } from 'antd/lib/form/Form';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useTasksStore } from 'src/modules/tasks/stores/context';
import { Utils } from 'src/modules/common/misc/Utils';
import { DashboardWidgetType } from '../types/DashboardWidget';
import { ALL_PROJECTS } from 'src/modules/tasks/screens/TasksPage';
import { useAdminStore } from '../stores/context';

const { RangePicker } = DatePicker;

type Props = {
    isVisible: boolean;
    closeDialog: () => void;
    updateCallBack?: () => void
};

const periodOptions = ['Day(s)', 'Week(s)', 'Month(s)', 'Year(s)'];
const DATE_FIELDS = ['dueDate', 'createDate', 'updateDate', 'reminder'];
export const EXCLUDED_TASK_FILTER_FIELDS = ['attachmentsCount', 'statusName', 'sessionIds', 'statistics', 
    'metadata', 'parentTask', 'taskId', 'projectId', 'description', 'subTasks', 'packageSetId', 'reminder',
    'senderName', 'accessType', 'sharedWith', 'errors', 'subTasksMeta'];
export const CURRENT_USER = 'current_user';
export const CreateWidgetDialog: React.FC<Props> = observer(({ isVisible, closeDialog, updateCallBack }) => {
    const rootConext = useStore();
    const {projectsStore} = rootConext;

    const adminContext = useAdminStore();
    const store = adminContext.widgetConstructorStore;

    const editableWidget  = store.editableWidget as DashboardWidgetMeta;
    const {taskPreviewVisualStore} = useTasksStore();
    const [form] = useForm();

    const getFieldNames = () => { 

        const options = [
            { 
                label: 'General Fields', 
                options: Object.keys(new Task())
                    .filter(k=> !EXCLUDED_TASK_FILTER_FIELDS.includes(k))
                    .map(key => ({ label: key, value: key })) 
                    .sort((a, b) => a.label.localeCompare(b.label))
            },

            { label: 'Metadata Fields', options: store.fitleredMetadata.map(m => ({ label: m.name, value: m.id })) }];
        return store.selectedProjectId === ALL_PROJECTS ? [options[0]] : options;
    };
    
    const transformFilters = () => {
        const filtersCopy = editableWidget!.filters.slice();
        const enrichedFilters = [] as (WidgetFilter & ExtraDateFilter)[];
        for (const [i, filter] of filtersCopy.entries()) {
            const enrichedFilter = Object.assign({}, filter) as WidgetFilter & ExtraDateFilter;
            if (DATE_FIELDS.includes(filter.fieldName)) {
                enrichedFilter.value = filter.value.map(v=> Utils.formatDatePickerValue(v));
            }
            if (typeof(filter.value[0]) === 'boolean') {
                enrichedFilter.value = filter.value.map(v=> v.toString());
            }
            enrichedFilter.periodCount = filter.extraFilters.extraDateFilter.periodCount;
            enrichedFilter.periodValue = filter.extraFilters.extraDateFilter.periodValue;
            enrichedFilters.push(enrichedFilter);
            store.setFieldNameDict(i, filter.fieldName);
        }
        // eslint-disable-next-line no-unused-vars
        const { filters, ...rest } = editableWidget!;
        return Object.assign({filters: enrichedFilters}, rest);
    };

    React.useEffect(() => {
        if (editableWidget && isVisible) {
            const transformedWidget = transformFilters();
            form.setFieldsValue(transformedWidget);
            form.setFieldValue('outputFieldName', editableWidget.outputField.map(o=> o.name));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableWidget?.id, isVisible]);

    const handleCloseDialog = () => {
        store.unselectProject();
        store.setEditableWidget(null);
        store.clearFieldTypeDict();
        store.setSelectedWidgetType(null);
        form.resetFields();
        closeDialog();
    };

    const getFieldTypeFor = (name: string) => {
        return store.metadata.map(m=> m.id).includes(name) ? 'Metadata' : 'General';
    };

    const prepareFilterValue = (value: unknown) => {
        if (!value) {
            return [];
        }
        return Array.isArray(value) ? value : [value];
    };

    const handleSubmit = () => {
        form.validateFields().then(async(values) => {
            if (values.filters) {
                values.filters = values.filters.map(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (f: any) => (new WidgetFilter(f.fieldName, getFieldTypeFor(f.fieldName),  
                        f.operator, prepareFilterValue(f.value), {extraDateFilter: {periodCount: f.periodCount, periodValue: f.periodValue}})));
            }
            if (Array.isArray(values.outputFieldName)) {
                values.outputField = values.outputFieldName.map((v: string) => ({name: v, type: getFieldTypeFor(v)}));
            } else if (values.outputFieldName) {
                values.outputField = [{name: values.outputFieldName, type: getFieldTypeFor(values.outputFieldName)}];
            }
            delete values.outputFieldName;
            if (values.projectId === ALL_PROJECTS) {
                values.projectId = null;
            }
            await store.createUpdateWidget(values);
            if (updateCallBack) {
                updateCallBack();
            }
            handleCloseDialog();
        });
    };

    const getValueWidget = (index: number) => {
        const fieldName = store.fieldNameDict[index];
        if (getFieldTypeFor(fieldName) === 'General') {
            switch (fieldName) {
            case 'dueDate':
            case 'createDate':
            case 'updateDate': 
                return <RangePicker format={Utils.getDateFormat()}/>;
            case 'createdBy':
            case 'updatedBy':
            case 'assignedTo':
            case 'followers':
                return <Select 
                    mode="multiple" 
                    options={[{label: CURRENT_USER, value: CURRENT_USER}, ...Object.entries(taskPreviewVisualStore.allUsersFullNameResolver).map(u=> ({label: u[1], value: u[0]}))]}
                />;
            case 'status':
                // eslint-disable-next-line max-len
                return <Select mode="multiple" options={store.filteredTaskStatuses.map(t=> ({value: t.id, label: `${t.name} (${store.taskTypes.find(type => type.id === t.typeId)!.name})`}))}/>;
            case 'priority':
                return <Select mode="multiple" options={[{value: 'LOW', label: 'Low'}, {value: 'MEDIUM', label: 'Medium'}, {value: 'HIGH', label: 'High'}]}/>;
            case 'taskType':
                return <Select mode="multiple" options={store.filteredTaskTypes.map(t=> ({value: t.id, label: t.label}))}/>;
            case 'isViewed':
            case 'isManuallyCreated':
            case 'isDone':
                return <Select options={[{value: 'true', label: 'true'}, {value: 'false', label: 'false'}]}/>;
            default:
                return <Select placeholder='Enter value' mode='tags' />;
            }
        }
        const fieldType = store.metadata.find(m=> m.id === fieldName)!.fieldType;
        switch(fieldType) {
        case 'DateTime': 
            return <RangePicker format={Utils.getDateFormat()}/>;
        default:
            return <Select placeholder='Enter value' mode='tags' />;
        }
    };

    const getWidgetsForPeriods = (index: number, field: FormListFieldData) => {
        return (
            hasDateType(index) &&
        <>
            <Form.Item
                name={[field.name, 'periodCount']}
                style={{ marginLeft: 15 }}
            >
                <InputNumber min={0} max={30}/>
            </Form.Item>
            <Form.Item  
                name={[field.name, 'periodValue']}
                style={{ width: 100, marginLeft: 15 }}>
                <Select options={periodOptions.map(p=> ({label: p, value: p.charAt(0)}))}/>
            </Form.Item>
        </>);
    };

    const handleFieldNameChange = (index: number, val: string) => {
        form.setFields([{errors: [], name: ['filters', index, 'value'], value: []}, {errors: [], name: ['filters', index, 'operator'], value: ''} ]);
        store.setFieldNameDict(index, val);
    };

    const getFilterOperators = (fieldName: string) => {
        const hasAllOperatrs = DATE_FIELDS.includes(fieldName) || ['DateTime', 'Number'].includes(store.getMetaById(fieldName)?.fieldType || '');
        const keys = hasAllOperatrs ? Object.keys(FilterOperator) :  Object.keys(FilterOperator).slice(0,2);
        return keys.map(k => ({ label: FilterOperator[k], value: k }));
    };

    const hasDateType = (index: number) => {
        return DATE_FIELDS.includes(store.fieldNameDict[index]) ||  (store.getMetaById(store.fieldNameDict[index])?.fieldType || '') === 'DateTime';
    };

    const handleWidgetTypeChange = (type: DashboardWidgetType) => {
        store.setSelectedWidgetType(type);
        if (type === DashboardWidgetType.List) {
            form.setFieldValue('outputFieldName', []);
        } else {
            form.setFieldValue('outputFieldName', '');
        }
    };

    const handleRemoveClick = (index: number, remove: (index: number) => void) => {
        store.removeFieldNameDict(index);
        remove(index);
    };

    return (
        <Modal
            open={isVisible}
            onCancel={closeDialog}
            maskClosable={false}
            closable={false}
            title={editableWidget ? 'Update Widget' : 'Create Widget'}
            destroyOnClose
            width={894}
            centered
            className="alpha-modal widgets-constructor"
            footer={[
                <Button className="light" data-id="add-user-dialog-cancel" size="large" key="back" onClick={handleCloseDialog}>
                    Cancel
                </Button>,
                <Button data-id="add-user-dialog-submit" size="large" key="submit" type="primary" onClick={handleSubmit}>
                    Save
                </Button>
            ]}
        >

            <Form
                layout="vertical"
                className="alpha-form"
                form={form}
            >
                <div style={{ marginBottom: 20 }}>
                    <Form.Item
                        name="name"
                        label={<span className="dialog-field-label">Name</span>}
                        rules={[{ required: true, message: 'Missing name' }]}
                        style={{ display: 'inline-block' }}
                    >
                        <Input style={{ width: 200 }} placeholder='Enter widget name' />
                    </Form.Item>
                    <FormItem
                        label={<span className="dialog-field-label">Project</span>}
                        name="projectId"
                        style={{ display: 'inline-block', marginLeft: 15 }}
                        rules={[{ required: true, message: 'Missing project' }]}
                        initialValue={ALL_PROJECTS}
                    >
                        <Select
                            style={{ width: 200 }}
                            options={[{label: 'All projects', value: ALL_PROJECTS}, ...(projectsStore.administrableProjects?.map(p => ({ label: p.name, value: p.id })) || [])]}
                            placeholder='Select project'
                            onChange={store.setSelectedProjectId}
                            showSearch
                            filterOption={Utils.filterOption}
                        />
                    </FormItem>
                    <FormItem
                        label={<span className="dialog-field-label">Widget Type</span>}
                        name="widgetType"
                        style={{ display: 'inline-block', marginLeft: 15 }}
                        rules={[{ required: true, message: 'Missing type' }]}
                        initialValue='List'
                    >
                        <Select
                            style={{ width: 200 }}
                            options={[
                                { label: 'List', value: DashboardWidgetType.List }, 
                                { label: 'Pie Chart', value: DashboardWidgetType.PieChart },
                                { label: 'Number', value: DashboardWidgetType.Numeric }
                            ]}
                            onChange={handleWidgetTypeChange}
                        />
                    </FormItem>
                    {store.currentWidgetType !== DashboardWidgetType.Numeric ? <Form.Item
                        name='outputFieldName'
                        label={<span className="dialog-field-label">Output Field</span>}
                        style={{ display: 'inline-block', marginLeft: 15 }}
                        rules={[{ required: true, message: 'Missing output field' }]}
                    >
                        <Select
                            mode={store.currentWidgetType === DashboardWidgetType.PieChart ? undefined: 'multiple'}
                            style={{ width: 200 }}
                            options={getFieldNames()}
                            placeholder='Select Output Field'
                            showSearch
                        />
                    </Form.Item> : null}
                </div>
                <Form.List name="filters">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, i) => (
                                <Row key={i}>
                                    <Observer>
                                        {() => (
                                            <>
                                                <Form.Item
                                                    name={[field.name, 'fieldName']}
                                                    rules={[{ required: true, message: 'Missing field name' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: 200 }}
                                                        options={getFieldNames()}
                                                        placeholder='Select Field'
                                                        onChange={(val) => handleFieldNameChange(i, val)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[field.name, 'operator']}
                                                    rules={[{ required:  true, message: 'Missing operator' }]}
                                                    style={{ width: 122, marginLeft: 15 }}
                                                >
                                                    <Select
                                                        options={getFilterOperators(store.fieldNameDict[i])}
                                                        placeholder='Select Operator'
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[field.name, 'value']}
                                                    rules={[{ required: hasDateType(i) ? false : true, message: 'Missing value' }]}
                                                    style={{ width: 250, marginLeft: 15 }}
                                                >
                                                    {getValueWidget(i)}
                                                </Form.Item>
                                                {getWidgetsForPeriods(i, field)}
                                                <MinusCircleOutlined onClick={() => handleRemoveClick(i, remove)} style={{ marginTop: 9, marginLeft: 10 }} />
                                            </>
                                        )}
                                    </Observer>
                                </Row>
                            ))}
                            <Form.Item style={{ marginTop: 15 }}>
                                <Button type="primary" onClick={() => { 
                                    add(); 
                                }} style={{ cursor: 'pointer' }}>Add filter</Button>
                            </Form.Item>
                        </>)}
                </Form.List>
            </Form>
        </Modal>
    );
});

export default CreateWidgetDialog;